import styled from "styled-components"
import { mediaMax } from "../../helpers/MediaQueries"

export const ModalWrapper = styled.div`
  padding: 36px 36px 48px 48px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - calc(100vh - 100%));
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
  letter-spacing: 0.1em;
  ${mediaMax.phoneXL`
    padding: 58px 24px 27px;
  `}
`

export const HeaderLogo = styled.div`
  display: flex;
  justify-content: space-between;
  img:last-child {
    cursor: pointer;
  }
`
export const ContactInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  ${mediaMax.phoneXL`
    flex-direction: column;
  `}
`
export const ContactTitle = styled.div`
  margin-right: 18.5%;
  ${mediaMax.phoneXL`
    margin: 0 0 36px 0;
  `}
  h2 {
    color: #151515;
    font-weight: 300;
  }
`
export const ContactItems = styled.div`
  .contact-item {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 36px;
    }
    h4 {
      color: #000;
      padding-bottom: 10px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      ${mediaMax.phoneXL`
        padding-bottom: 12px;
        font-size: 16px;
      `}
    }
    h5 {
      color: #000;
      text-transform: uppercase;
      font-weight: 400;
      font-family: "TT Norms", serif;
      font-size: 12px;
      line-height: 18px;
      &.bolder {
        font-weight: 500;
      }
    }
    div,
    a,
    span {
      font-family: "Adobe Caslon Pro", sans-serif;
      color: #000;
      transition: 0.25s ease-out;
      cursor: pointer;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0;
      ${mediaMax.phoneXL`
        font-size: 16px;
        line-height: 20px;
      `}
    }
    a:hover,
    span:hover {
      opacity: 0.75;
    }
    div {
      cursor: default;
    }
    .tt-link {
      font-family: "TT Norms", sans-serif;
      font-weight: 300;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      &.bolder {
        max-width: 462px;
        font-weight: 500;
      }
      a {
        all: inherit;
        display: inline;
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
    .tt-link:not(:last-child) {
      margin-bottom: 36px;
    }
  }
  .socials {
    &:not(:last-child) {
      margin-bottom: 36px;
    }
    img {
      margin-right: 13px;
    }
  }
`
export const FooterLogo = styled.div`
  ${mediaMax.phoneXL`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    .logos-container {
      max-width: 240px;
      display: flex;
      justify-content: flex-start;
    }
    span {
      font-family: 'TT Norms', serif;
      font-weight: 300;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #292929;
      cursor: pointer;
      padding-bottom: 4px;
    }
  `}
  img {
    margin-right: 36px;
    ${mediaMax.phoneXL`
      display: block;
      max-width: 81%;
      margin-right: 0;
    `}
  }
`
