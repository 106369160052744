import React, { FC, useContext, useEffect, useRef } from "react"
import { Link } from "gatsby"
import { FooterNav, HeaderWrap, Logo, Nav } from "./index.styled"
import { Context } from "../../context/context"
import main_logo from "../../assets/images/main_logo_2.svg"
import main_logoDark from "../../assets/images/main_logo_2-dark.svg"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import gsap from "gsap"

interface IHeader {
  location: Location
}

const Header: FC<IHeader> = ({ location }) => {
  const headerLogo = useRef(null)
  const { setOpenContact, setOpenLegal, menuTheme, setMenuTheme } = useContext(Context)

  useEffect(() => {
    gsap.timeline({
      scrollTrigger: {
        trigger: ".hide-logo",
        start: "top top",
        end: "bottom top",
        preventOverlaps: true,
        toggleActions: "restart none none reverse",
        id: "headerScrollRev",
        scrub: true
      },
    }).to(
      headerLogo.current,
      {opacity: 0, y: "-18vh", ease: "ease-out"},
    )

    if (location.pathname.includes("/neighborhood")) {
      setMenuTheme("full-dark");
    } else {
      setMenuTheme("full-light");
    }
    return () => {
      ScrollTrigger.getById("headerScrollRev")?.kill();
    }
  }, [location])

  return (
    <HeaderWrap>
      <Nav className={`prim ${menuTheme}`} id={"hNav"}>
        <Link
          className={location.pathname === "/" ? 'active' : ''}
          to={"/"}
        >
          BUILDING
        </Link>
        <Link
          className={location.pathname.includes("/neighborhood") ? 'active' : ''}
          to={"/neighborhood"}
        >
          NEIGHBORHOOD
        </Link>
        <Link
          className={location.pathname.includes("/amenities") ? 'active' : ''}
          to={"/amenities"}
        >
          AMENITIES
        </Link>
        <Link
          to={"https://mapleterraceresidences.com/"}
          target="_blank"
        >
          RESIDENCES
        </Link>
      </Nav>
      <Logo
        ref={headerLogo}
        id={"hLogo"}
        src={menuTheme === "full-dark" ? main_logoDark : main_logo}
        alt={"Maple Terrace Uptown Dallas"}
      />
      <Nav className={`sec ${menuTheme}`} id={"sNav"}>
        <Link
          to={"/team"}
          className={"active"}
          onClick={()=>sessionStorage.setItem('teamNavigate', location.pathname)}
        >
          TEAM
        </Link>
        <span onClick={() => setOpenContact(true)} >
          CONTACT
        </span>
      </Nav>
      <FooterNav id={"hFooter"}>
        <h5 onClick={() => setOpenLegal(true)}>LEGAL</h5>
        <p>©2021 Hines</p>
      </FooterNav>
    </HeaderWrap>
  )
}

export default Header
