import React, {FC, useContext, useEffect, useRef, useState} from "react"
import { Link } from "gatsby"
import { HeaderNav, HeaderWrap, Logo, Nav, BurgerBtn, CloseMenuBtn } from "./index.styled"
import { Context } from "../../context/context"
import main_logo from "../../assets/images/main_logo_mob.svg"
import main_logoDark from "../../assets/images/main_logo_mob-dark.svg"
import { ScrollTrigger } from "gsap/ScrollTrigger"

interface IHeader {
  location: Location
}

const HeaderMob: FC<IHeader> = ({ location }) => {
  const headerLogo = useRef(null)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const { setOpenContact, setOpenLegal, menuTheme, setMenuTheme } = useContext(Context)

  useEffect(() => {
    if (location.pathname.includes("/neighborhood")) {
      setMenuTheme("full-dark");
    } else {
      setMenuTheme("full-light");
    }
    return () => {
      ScrollTrigger.getById("headerScrollRev")?.kill();
    }
  }, [])

  useEffect(() => {
    document.body.classList.toggle('menu-open', isMenuOpen);
  },[isMenuOpen])  

  return (
    <HeaderWrap>
      <Logo
        ref={headerLogo}
        src={menuTheme === "full-dark" ? main_logoDark : main_logo}
        alt={"Maple Terrace Uptown Dallas"}
      />
      <BurgerBtn
        onClick={()=> setIsMenuOpen(true)}
      >
        <div className={menuTheme === "full-dark" ? 'dark' : ''}/>
      </BurgerBtn>
      <HeaderNav className={isMenuOpen? 'active' : ''}>
        <Logo
          ref={headerLogo}
          src={main_logo}
          alt={"Maple Terrace Uptown Dallas"}
        />
        <CloseMenuBtn onClick={()=> setIsMenuOpen(false)}/>
        <Nav className={`prim ${menuTheme}`}>
          <Link
            className={location.pathname === "/" ? 'active' : ''}
            to={"/"}
            onClick={()=> setIsMenuOpen(false)}
          >
            BUILDING
          </Link>
          <Link
            activeClassName={'active'}
            to={"/neighborhood"}
            onClick={()=> setIsMenuOpen(false)}
          >
            NEIGHBORHOOD
          </Link>
          <Link
            activeClassName={'active'}
            to={"/amenities"}
            onClick={()=> setIsMenuOpen(false)}
          >
            AMENITIES
          </Link>
          <Link
            to={"https://mapleterraceresidences.com/"}
            target="_blank"
            onClick={()=> setIsMenuOpen(false)}
          >
            RESIDENCES
          </Link>
        </Nav>
        <Nav className={`sec ${menuTheme}`}>
          <div>
            <Link
              to={"/team"}
              className={"active"}
              onClick={()=> {
                setIsMenuOpen(false)
                sessionStorage.setItem('teamNavigate', location.pathname)
              }}
            >
              TEAM
            </Link>
            <span onClick={() => {
              setOpenContact(true)
              setIsMenuOpen(false)
            }} className={'contact-btn'}>
              CONTACT
            </span>
          </div>
          <span onClick={() => {
            setOpenLegal(true)
            setIsMenuOpen(false)
          }}>
            LEGAL
          </span>
        </Nav>

      </HeaderNav>

    </HeaderWrap>
  )
}

export default HeaderMob
