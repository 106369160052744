import React, { Dispatch, SetStateAction } from "react"

interface IContextProps {
  openContact: boolean
  setOpenContact: Dispatch<SetStateAction<boolean>>
  openLegal: boolean
  setOpenLegal: Dispatch<SetStateAction<boolean>>
  menuTheme: "full-dark" | "full-light" | "half-dark"
  setMenuTheme: Dispatch<SetStateAction<IContextProps["menuTheme"]>>
}

export const Context = React.createContext<IContextProps>({
  openContact: false,
  setOpenContact(value: ((prevState: boolean) => boolean) | boolean): void {
    /* **/

  },
  openLegal: false,
  setOpenLegal(value: ((prevState: boolean) => boolean) | boolean): void {
    /* **/
  },
  menuTheme: "full-light",
  setMenuTheme(value: ((prevState: IContextProps["menuTheme"]) => IContextProps["menuTheme"]) | IContextProps["menuTheme"]): void {
    /* **/
  },
})
