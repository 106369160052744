import styled from "styled-components"

export const HeaderWrap = styled.header`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  a,
  span {
    margin: 0 24px;
    transition: 0.4s ease-out;
    letter-spacing: 0.1em;
    cursor: pointer;
    &.contact-btn {
      margin-left: 12px;
    }
  }
`
export const CloseMenuBtn = styled.div`
  position: absolute;
  top: 55px;
  right: 24px;
  width: 24px;
  height: 24px;
  z-index: 12;
  cursor: pointer;
  &:before, &:after {
    content: '';
    width: 24px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: calc(50% - 1px);
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;
export const BurgerBtn = styled.div`
  position: fixed;
  top: 64px;
  right: 21px;
  width: 30px;
  height: 15px;
  cursor: pointer;
  z-index: 11;
  >div {
    position: absolute;
    top: calc(50% - 1px);
    width: 100%;
    height: 2px;
    background-color: #fff;
    &:before, &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #fff;
      top: -8px;
    }
    &:after {
      top: 8px;
    }
    &.dark {
      background-color: #000;
      &:before, &:after {
        background-color: #000;
      }
    }
  }
`

export const Logo = styled.img`
  position: absolute;
  left: 24px;
  top: 58px;
  z-index: 11;
`

export const HeaderNav = styled.div`
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: calc(100vh - calc(100vh - 100%));
  display: flex;
  align-items: center;
  background-color: #292929;
  z-index: 12;
  transition: right .5s ease-out;
  &.active {
    right: 0;
  }
`;
export const Nav = styled.nav`
  font-family: "TT Norms", serif;
  a.active {
    opacity: 1;
  }
  &.prim {
    font-size: 35px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    a:not(:last-child), span:not(:last-child) {
      padding-bottom: 30px;
    }
  }
  &.sec {
    position: absolute;
    bottom: 26px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    padding-top: 32px;
    justify-content: space-between;
    span {
      opacity: 1;
    }
  }
`
