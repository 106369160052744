import styled from "styled-components"
import {mediaMax} from "../../helpers/MediaQueries";

export const ModalWrapper = styled.div`
  padding: 36px 36px 48px 48px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - calc(100vh - 100%));
  background-color: #fff;
  z-index: 100;
  overflow: auto;
  scroll-behavior: smooth;
  ${mediaMax.phoneXL`
    padding: 0 24px 27px;
  `}
`

export const HeaderLogo = styled.div`
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 11;
  width: 93%;
  ${mediaMax.phoneXL`
    background-color: #fff;
    padding-top: 58px;
    width: calc(100% - 48px);
  `}
  img:last-child {
    cursor: pointer;
  }
`
export const LegalContent = styled.div`
  display: flex;
  aside {
    position: fixed;
    display: flex;
    flex-direction: column;
    padding-right: 14px;
    padding-top: 9.25vh;
    ${mediaMax.phoneXL`
      background-color: #fff;
      padding-top: 190px;
      flex-direction: row;
      width: 100%;
    `}
    a {
      font-family: "TT Norms", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #000000;
      opacity: 0.25;
      transition: 0.25s ease-out;
      padding-bottom: 36px;
      ${mediaMax.phoneXL`
        font-size: 14px;
        line-height: 18px;
        padding-bottom: 16px;
        margin-right: 33px;
      `}
      &:hover {
        opacity: 0.5;
      }
    }
  }
  section {
    color: #292929;
    width: 66%;
    margin-left: 25%;
    ${mediaMax.phoneXL`
      width: 100%;
      margin-left: 0;
    `}
    a,
    p {
      padding-top: 10vh;
      color: #292929;
      ${mediaMax.phoneXL`
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        padding-top: 270px;
        &#terms {
          margin-bottom: -200px;  
        }
      `}
    }
  }
`
