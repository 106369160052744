import React, { FC, useEffect, useRef, useState } from "react"
import { Context } from "../../context/context"
import { GlobalStyle } from "./index.styled"
import RotateScreen from "../rotate";
import useWindowSize from "../../helpers/windowSize";
import ContactModal from "../contact"
import gsap from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CSSPlugin } from "gsap/CSSPlugin"
import LegalModal from "../legal"
import VideoPreload from "../video-preload"
import Header from "../header"
import HeaderMob from "../header-mob"
import { Helmet } from "react-helmet";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, CSSPlugin)
gsap.config({
  nullTargetWarn: false,
})

interface LayoutProps {
  children: any
  location: Location
}

const Layout: FC<LayoutProps> = ({ children, location }) => {
  const {width} = useWindowSize();
  const layoutWrapRef = useRef(null)
  const [openContact, setOpenContact] = useState(false)
  const [openLegal, setOpenLegal] = useState(false)
  const [menuTheme, setMenuTheme] = useState<"full-dark" | "full-light" | "half-dark">("full-light")
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  if (typeof window === `undefined`) {
    return <></>
  }

  useEffect(()=>{
    window.addEventListener('resize', () => {
      ScrollTrigger.getAll().forEach(ST => {
        ST.refresh()
      })
    })
    return () => ScrollTrigger.getAll().forEach(ST => ST.refresh())
  }, [])
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".hide-legal",
          start: "bottom top+=40%",
          end: "bottom top",
          scrub: 1,
          toggleActions: "play none none reverse",
        },
      })
      .to("#hFooter", {right: "-15%", ease: "ease-out"}, 0)
    Promise.all(
      Array.from(document.images).map(img => {
        if (img.complete) return Promise.resolve(img.naturalHeight !== 0)
        return new Promise(resolve => {
          img.addEventListener("load", () => resolve(true))
          img.addEventListener("error", () => resolve(false))
        })
      })
    ).then(results => {
      if (results.every(res => res)) {
        setTimeout(() => {
          ScrollTrigger.getAll().forEach(ST => ST.refresh())
        }, 600)
        console.log("all images loaded successfully")
      } else {
        console.log("some images failed to load, all finished loading")
      }
    })
    // ScrollTrigger.getAll().forEach(ST => {
    //   ST.refresh()
    // })
    // This in case a scroll animation is active while the route is updated
    gsap.killTweensOf(window)
    return () => ScrollTrigger.getAll().forEach(ST => ST.refresh())
  }, [])

  const href = location.href + 'images/opengraph.png';

  return (
    <Context.Provider
      value={{
        openContact,
        setOpenContact,
        openLegal,
        setOpenLegal,
        menuTheme,
        setMenuTheme
      }}
    >
      <Helmet defer={false}>
        <meta property="og:image" content={href}/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="630"/>
      </Helmet>
      <GlobalStyle openContact={openContact} openLegal={openLegal} />
      {openContact && <ContactModal />}
      {openLegal && <LegalModal />}
      {( width > 576 && width < 1024) ?
        <RotateScreen/>
        :
        <>
        {!location.pathname.includes("/team") && width > 1023 && <Header location={location} />}
        {width < 577 && <HeaderMob location={location} />}
          <div className={"container"} ref={layoutWrapRef}>
            {children}
          </div>
        </>
      }
      {isFirstLoad && location.pathname === "/" && (
        <VideoPreload setIsFirstLoad={setIsFirstLoad} />
      )}
    </Context.Provider>
  )
}
export default Layout
